import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Videos from 'components/category/videos'
import Copy from 'components/category/copy'
import { graphql } from 'gatsby'
import Content from 'components/category/content'
import FeaturesGrid from 'components/features-grid'

const items = [
  {
    link: '/stihl/lawn-mowers/homeowner-lawn-mowers/',
    imageId: 'homeownerLawnMowersImage',
    name: 'Homeowner Lawn Mowers',
    alt: 'STIHL Homeowner Lawn Mowers',
  },
  {
    link: '/stihl/lawn-mowers/robotic-mowers/',
    imageId: 'roboticMowersImage',
    name: 'Robotic Mowers',
    alt: 'STIHL Robotic Mowers',
  },
]

const LawnMowersPage = ({ data }) => {
  const { allHutsonPromotion, heroImage, mulchImage, leavesImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Lawn Mowers | Hutson Inc</title>
        <meta
          name='description'
          content='Maintain a great looking lawn with ease using a quiet and lightweight battery-powered lawn mower from STIHL or automate it entirely with a STIHL robotic mower.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Lawn Mowers',
                'item': 'https://www.hutsoninc.com/stihl/lawn-mowers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Lawn Mowers' />

      <Content>
        <Floater items={floaterItems} />

        <Copy header='About STIHL Lawn Mowers'>
          <p>
            Whether you’re looking to maintain a small lawn, keep your backyard in shape, or reach
            places your riding mower can’t, the STIHL battery-powered lawn mowers are for you.
            Cordless lawn mowers are ideal for properties around or under .25 acres. They’re much
            lighter than gas-powered models, making them easier to push and maneuver around the
            lawn. No gasoline or oil is required, so maintenance is a breeze. Push and
            self-propelled lawn mower drive options are available for STIHL push mowers.
          </p>
          <p>
            If you’re looking for a lawn mower that does all the work for you, check out STIHL
            robotic mowers. These handy machines are perfect for lawns between .25 and 1 acre and
            can mow on slopes up to 24 degrees. Your lawn will be maintained around the clock,
            giving you more time to spend on other things and saving you money. STIHL iMow robotic
            mowers have advanced technology allowing them to path around the lawn without missing
            any spots and dock whenever the battery is low, or weather conditions are poor.
          </p>
        </Copy>

        <Promos data={promos} type='STIHL Lawn Mower' />

        <Videos
          type='STIHL Lawn Mower'
          id='gI1gHWUwsWs'
          list='PLUz5fj7f_mw90VcG2OMsI2BgA6Y6pN2h6'
        />
      </Content>

      <FeaturesGrid
        features={[
          {
            image: mulchImage.childImageSharp.gatsbyImageData,
            imageAlt: 'Man holding grass clippings in his palm',
            title: 'Mulching vs bagging clippings',
            copy: "Not sure if you should be mulching, bagging, or discharging your clippings? Check out our guide on the pros and cons of each to find out which one's right for you.",
            button: {
              link: '/blog/mulching-vs-bagging/',
              text: 'Read the bagging vs mulching guide',
            },
          },
          {
            image: leavesImage.childImageSharp.gatsbyImageData,
            imageAlt: 'Woman emptying the bag from a STIHL RMA460 lawn mower',
            title: 'Cleaning up leaves with STIHL lawn mowers',
            copy: 'All STIHL walk-behind mowers come standard with mulching and bagging capabilities, allowing them to easily chop up and bag leaves spread across your lawn.',
            button: {
              link: '/blog/dealing-with-leaves/#using-walk-behind-mowers-to-clean-up-leaves',
              text: 'How to use walk-behind mowers to clean up leaves',
            },
          },
        ]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query lawnMowersStihlQuery($category: String = "lawn-mowers") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/lawn-mowers/lawn-mowers-bg.jpg" }) {
      ...FullWidthImage
    }
    homeownerLawnMowersImage: file(
      relativePath: { eq: "stihl/lawn-mowers/homeowner-lawn-mowers.jpg" }
    ) {
      ...FloatingGridImage
    }
    roboticMowersImage: file(relativePath: { eq: "stihl/lawn-mowers/robotic-mowers.jpg" }) {
      ...FloatingGridImage
    }
    mulchImage: file(relativePath: { eq: "stihl/lawn-mowers/walk-behind-lawn-mower-mulch.jpg" }) {
      ...SharpImage900
    }
    leavesImage: file(relativePath: { eq: "stihl/lawn-mowers/emptying-stihl-rma460-bag.jpg" }) {
      ...SharpImage900
    }
  }
`

export default LawnMowersPage
